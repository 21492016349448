import React, { ReactNode, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { FinGuardLogo } from '../Logo/FinGuardLogo'
import { HaloBooksLogo } from '../Logo/HaloBooksLogo'
import { CustomizedLogo } from '../Logo/CustomizedLogo'
import { useSidebarContext } from '../../contexts/SidebarContext'
import AccountingIcon from '../../icons/Accounting'
import FileIcon from '../../icons/File'
import CoinsSwapIcon from '../../icons/CoinsSwap'
import CoinsHandIcon from '../../icons/CoinsHand'
import OpenIcon from '../../icons/Open'
import TickIcon from '../../icons/Tick'
import { ColorPicker } from '../ColorPicker/ColorPicker'

import './styles.css'
import { useLayerContext } from '@layerfi/components'

interface ThemeConfig {
  name: string
  logo?: ReactNode
  light: string
  dark: string
}

interface CustomColorsProps {
  light: string
  dark: string
}

const originalThemes: ThemeConfig[] = [
  {
    name: 'FinGuard',
    logo: <FinGuardLogo />,
    light: '#34ACB2',
    dark: '#23211F',
  },
  {
    name: 'HaloBooks',
    logo: <HaloBooksLogo />,
    light: '#FFE01B',
    dark: '#23211F',
  },
  {
    name: 'Customized',
    light: '#34ACB2',
    dark: '#23211F',
  },
]

const getCustomized = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return originalThemes.find(x => x.name === 'Customized')!
}

const getDefaultCustomColors = () => {
  try {
    const colorsLS = localStorage.getItem('customColors')
    if (colorsLS) {
      return JSON.parse(colorsLS)
    }

    return {
      light: getCustomized().light,
      dark: getCustomized().dark,
    }
  } catch (_err) {
    return {
      light: getCustomized().light,
      dark: getCustomized().dark,
    }
  }
}

export const Sidebar = () => {
  const { setLightColor, setDarkColor, setColors } = useLayerContext()
  const { openSidebar } = useSidebarContext()
  const [isOpen, setIsOpen] = useState(false)
  const [showColors, setShowColors] = useState(false)
  const [selectedTheme, setSelectedTheme] = useState(originalThemes[0])
  const [customColors, setCustomColors] = useState<CustomColorsProps>(
    getDefaultCustomColors(),
  )

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  const setCustomColorsWithLS = (colors: CustomColorsProps) => {
    setCustomColors(colors)
    localStorage.setItem('customColors', JSON.stringify(colors))
  }

  const getLogo = (theTheme: ThemeConfig) => {
    if (theTheme.name === 'Customized') {
      return (
        <CustomizedLogo
          color={customColors.light}
          textColor={customColors.dark}
        />
      )
    }

    return theTheme.logo
  }

  const handleThemeChange = (theme?: ThemeConfig) => {
    if (!theme) {
      return
    }
    setSelectedTheme(theme)

    if (theme.name !== 'Customized') {
      setColors({
        light: { hex: theme.light },
        dark: { hex: theme.dark },
      })
      setIsOpen(false)
      setShowColors(false)
    }
  }

  const toggleColors = () => {
    setShowColors(!showColors)
  }

  const handleLightColorChange = (color: string) => {
    if (color) {
      setLightColor({ hex: color })
      setCustomColorsWithLS({
        ...customColors,
        light: color,
      })
      setSelectedTheme(getCustomized())
    } else {
      setLightColor(undefined)
    }
  }

  const handleDarkColorChange = (color: string) => {
    if (color) {
      setDarkColor({ hex: color })
      setCustomColorsWithLS({
        ...customColors,
        dark: color,
      })
      setSelectedTheme(getCustomized())
    } else {
      setDarkColor(undefined)
    }
  }

  const setCurrentCustomColors = () => {
    if (customColors.light && customColors.dark) {
      setCustomColorsWithLS({ ...customColors })
      setColors({
        light: { hex: customColors.light },
        dark: { hex: customColors.dark },
      })
    }
  }

  return (
    <section className='sidebar'>
      <div
        className={`sidebar-bg ${isOpen ? 'sidebar-bg-open' : ''}`}
        onClick={toggleAccordion}
      />
      <div className='sidebar-content'>
        <div className='sidebar-header'>
          <div
            className={`switch-theme-wrapper ${
              isOpen ? 'switch-theme-wrapper-open' : ''
            }`}
          >
            <button
              className={`switch-theme-button ${
                isOpen ? 'switch-theme-button-open' : ''
              }`}
              onClick={toggleAccordion}
            >
              {getLogo(selectedTheme)}
              {isOpen ? (
                <TickIcon />
              ) : (
                <div>
                  <OpenIcon />
                </div>
              )}
            </button>
            <div
              className={`switch-theme-content ${
                isOpen
                  ? 'switch-theme-content-open'
                  : 'switch-theme-content-closed'
              }`}
            >
              {isOpen && (
                <>
                  {originalThemes
                    .filter(
                      x =>
                        x.name !== selectedTheme.name &&
                        x.name !== 'Customized',
                    )
                    .map(t => {
                      return (
                        <div
                          key={t.name}
                          className='switch-theme-button-1'
                          onClick={() => handleThemeChange(t)}
                        >
                          {getLogo(t)}
                        </div>
                      )
                    })}

                  <div
                    className={`color-button ${
                      showColors ? 'color-button-open' : 'color-button'
                    }`}
                    onClick={e => {
                      e.stopPropagation()
                      toggleColors()
                      setCurrentCustomColors()
                      handleThemeChange(
                        originalThemes.find(t => t.name === 'Customized'),
                      )
                    }}
                  >
                    <span className='color-button-text'>
                      <svg
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect width='18' height='18' rx='5' fill='#F0F0F2' />
                        <rect
                          x='0.5'
                          y='0.5'
                          width='17'
                          height='17'
                          rx='4.5'
                          stroke='#707888'
                          strokeOpacity='0.07'
                        />
                        <path
                          d='M9 5.12085L11.3583 7.47918C11.8247 7.94527 12.1424 8.53921 12.2712 9.18587C12.4 9.83254 12.3341 10.5029 12.0819 11.1121C11.8297 11.7213 11.4024 12.242 10.8542 12.6084C10.306 12.9748 9.66145 13.1703 9.00208 13.1703C8.34272 13.1703 7.69817 12.9748 7.14995 12.6084C6.60174 12.242 6.1745 11.7213 5.92227 11.1121C5.67005 10.5029 5.60416 9.83254 5.73296 9.18587C5.86175 8.53921 6.17944 7.94527 6.64583 7.47918L9 5.12085Z'
                          stroke='#707888'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      Personalize color mode
                    </span>
                    {selectedTheme.name === 'Customized' && <TickIcon />}
                  </div>
                  <div
                    className={`colors-closed ${
                      showColors || selectedTheme.name === 'Customized'
                        ? 'colors-open'
                        : 'colors-closed'
                    }`}
                  >
                    <ColorPicker
                      color={customColors.light}
                      label='Accent color'
                      onChange={handleLightColorChange}
                      colors={[
                        '#47999E',
                        '#FFE01B',
                        '#1BFFAD',
                        '#1B9FFF',
                        '#FFA41B',
                        '#BA77EE',
                        '#EE77BE',
                        '#B2EE77',
                        '#EEA977',
                        '#87E',
                      ]}
                    />
                    <ColorPicker
                      color={customColors.dark}
                      label='Text color'
                      onChange={handleDarkColorChange}
                      colors={[
                        '#114548',
                        '#444235',
                        '#106345',
                        '#07416A',
                        '#553A13',
                        '#230838',
                        '#411A31',
                        '#253417',
                        '#271E18',
                        '#3A3945',
                      ]}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <ul>
          <li>
            <NavLink
              to='/'
              className='nav-link active'
              onClick={() => openSidebar(false)}
            >
              <AccountingIcon />
              Accounting
            </NavLink>
          </li>
          <li>
            <span className='nav-link disabled'>
              <FileIcon /> Invoices
            </span>
          </li>
          <li>
            <span className='nav-link disabled'>
              <CoinsHandIcon /> Payments
            </span>
          </li>
          <li>
            <span className='nav-link disabled'>
              <CoinsSwapIcon /> Payouts
            </span>
          </li>
        </ul>
      </div>
      <button className='sidebar-close-btn' onClick={() => openSidebar(false)}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 18 18'
          fill='none'
        >
          <path
            d='M13.5 4.5L4.5 13.5'
            stroke='black'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M4.5 4.5L13.5 13.5'
            stroke='black'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </button>
      <div className='sidebar-profile'>
        <img src='/images/profile-img.png' className='profile-image' />
        <div className='profile'>
          <span className='name'>Andrea Smith</span>
        </div>
      </div>
    </section>
  )
}
