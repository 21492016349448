import React from 'react'

import './styles.css'

export const CustomizedLogo = ({
  color,
  textColor,
}: {
  color: string
  textColor: string
}) => {
  return (
    <div className='logo'>
      <span className='logo__signet'>
        <svg
          width='18'
          height='18'
          viewBox='0 0 18 18'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect width='18' height='18' rx='5' fill={color} />
          <rect
            x='0.5'
            y='0.5'
            width='17'
            height='17'
            rx='4.5'
            stroke={color}
            strokeOpacity='0.07'
          />
          <path
            d='M9 5.12085L11.3583 7.47918C11.8247 7.94527 12.1424 8.53921 12.2712 9.18587C12.4 9.83254 12.3341 10.5029 12.0819 11.1121C11.8297 11.7213 11.4024 12.242 10.8542 12.6084C10.306 12.9748 9.66145 13.1703 9.00208 13.1703C8.34272 13.1703 7.69817 12.9748 7.14995 12.6084C6.60174 12.242 6.1745 11.7213 5.92227 11.1121C5.67005 10.5029 5.60416 9.83254 5.73296 9.18587C5.86175 8.53921 6.17944 7.94527 6.64583 7.47918L9 5.12085Z'
            stroke={textColor}
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </span>
      <span className='logo__text'>Your color</span>
    </div>
  )
}
