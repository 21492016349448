import React from 'react'
import { Reports as ReportsView } from '@layerfi/components'
import { AccountingLayout } from '../../../layouts/AccountingLayout'

export const Reports = () => {
  return (
    <AccountingLayout>
      <ReportsView />
    </AccountingLayout>
  )
}
