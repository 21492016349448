import { AccountingOverview as AccountingOverviewView } from '@layerfi/components'
import { AccountingLayout } from '../../../layouts/AccountingLayout'

import './styles.css'

export const AccountingOverview = () => {
  return (
    <AccountingLayout>
      <AccountingOverviewView />
    </AccountingLayout>
  )
}
