import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { LayerProvider } from '@layerfi/components'
import '@layerfi/components/dist/styles/index.css'

import { Root } from './Root'
import { AccountingOverview } from './pages/accounting/overview'
import { BankTransactions } from './pages/accounting/bank-transactions'
import { Reports } from './pages/accounting/reports'

import './themes/AppTheme.css'
import './themes/LayerTealTheme.css'
import './themes/LayerGreenTheme.css'
import './App.css'
import { SidebarContext, useSidebar } from './contexts/SidebarContext'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '/',
        element: <AccountingOverview />,
      },
      {
        path: 'accounting/bank-transactions',
        element: <BankTransactions />,
      },
      {
        path: 'accounting/reports',
        element: <Reports />,
      },
    ],
  },
])

function App() {
  const sidebarData = useSidebar()

  return (
    <div className='root-container'>
      <LayerProvider
        businessId={process.env.REACT_APP_BUSINESS_ID ?? ''}
        environment={process.env.REACT_APP_ENVIRONMENT ?? ''}
        appId={process.env.REACT_APP_APP_ID ?? ''}
        appSecret={process.env.REACT_APP_APP_SECRECT ?? ''}
      >
        <SidebarContext.Provider value={sidebarData}>
          <RouterProvider router={router} />
        </SidebarContext.Provider>
      </LayerProvider>
    </div>
  )
}

export default App
