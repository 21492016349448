import React from 'react'
import { BankTransactionsWithLinkedAccounts } from '@layerfi/components'
import { AccountingLayout } from '../../../layouts/AccountingLayout'

export const BankTransactions = () => {
  return (
    <AccountingLayout>
      <BankTransactionsWithLinkedAccounts />
    </AccountingLayout>
  )
}
