import { Outlet } from 'react-router-dom'
import { Sidebar } from './components/Sidebar'
import { useSidebarContext } from './contexts/SidebarContext'

export const Root = () => {
  const { isSidebarOpen } = useSidebarContext()

  return (
    <div className={`structure sidebar--${isSidebarOpen ? 'open' : 'closed'}`}>
      <Sidebar />
      <section className='main-content'>
        <Outlet />
      </section>
    </div>
  )
}
