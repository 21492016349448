import React, { CSSProperties, useEffect, useState } from 'react'
import { BlockPicker, ColorResult } from 'react-color'
import './styles.css'

interface ColorPickerProps {
  label?: string
  color?: string
  onChange: (color: string) => void
  defaultColor?: string
  colors?: string[]
}

export const ColorPicker = ({
  label,
  color = '#000000',
  onChange,
  defaultColor = '#000000',
  colors,
}: ColorPickerProps) => {
  const [currentColor, setCurrentColor] = useState(color || defaultColor)
  const [displayColorPicker, setDisplayColorPicker] = useState(false)

  useEffect(() => {
    setCurrentColor(color)
  }, [color])

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
  }

  const handleBlockPickerChange = (newColor: ColorResult) => {
    if (newColor?.hex) {
      setCurrentColor(newColor.hex)
      onChange(newColor.hex)
    }
  }

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = e.target.value
    if (newColor) {
      setCurrentColor(newColor)
      onChange(newColor)
    }
  }

  const styles: Record<string, CSSProperties> = {
    color: {
      width: '20px',
      height: '20px',
      borderRadius: '4px',
      backgroundColor: currentColor,
    },
    swatch: {
      background: '#fff',
      borderRadius: '6px',
      boxShadow: '0px 4px 4px rgba(19, 19, 22, 0.03)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
      position: 'absolute',
      zIndex: 2,
    },
    cover: {
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  }

  return (
    <div className='color-picker'>
      <div className='color-picker-wrapper'>
        <div>{label}</div>
        <div className='color-picker-input-wrapper'>
          <div className='color-picker-sketch'>
            <div style={styles.swatch} onClick={handleClick}>
              <div style={styles.color} />
            </div>
            {displayColorPicker && (
              <div style={styles.popover}>
                <div style={styles.cover} onClick={handleClose} />
                <BlockPicker
                  color={currentColor}
                  onChange={handleBlockPickerChange}
                  colors={colors}
                />
              </div>
            )}
          </div>
          <input
            type='text'
            value={currentColor}
            onChange={handleColorChange}
          />
        </div>
      </div>
    </div>
  )
}
