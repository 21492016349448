import * as React from 'react'
import { IconSvgProps } from './types'

const Open = ({ size = 16, ...props }: IconSvgProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 16 16'
    fill='none'
    {...props}
    width={size}
    height={size}
  >
    <g clipPath='url(#clip0_1936_71262)'>
      <path
        d='M12 5.33325L8 1.33325L4 5.33325'
        stroke='#707888'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4 10.6667L8 14.6667L12 10.6667'
        stroke='#707888'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1936_71262'>
        <rect
          width='16'
          height='16'
          fill='white'
          transform='translate(0 16) rotate(-90)'
        />
      </clipPath>
    </defs>
  </svg>
)

export default Open
