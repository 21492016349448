import * as React from 'react'
import { IconSvgProps } from './types'

const CoinsHand = ({ size = 14, ...props }: IconSvgProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 15 15'
    fill='none'
    {...props}
    width={size}
    height={size}
  >
    <g clipPath='url(#clip0_1626_6719)'>
      <path
        d='M8.46809 5.75191C8.13418 5.98996 7.72555 6.12999 7.28422 6.12999C6.15664 6.12999 5.24255 5.21591 5.24255 4.08833C5.24255 2.96075 6.15664 2.04666 7.28422 2.04666C8.01513 2.04666 8.65633 2.43074 9.01702 3.00808M4.07589 12.5975H5.59856C5.79709 12.5975 5.9944 12.6211 6.18686 12.6684L7.79574 13.0593C8.14484 13.1444 8.50851 13.1527 8.86125 13.0841L10.6401 12.7381C11.11 12.6465 11.5423 12.4215 11.8811 12.092L13.1397 10.8677C13.4991 10.5186 13.4991 9.95225 13.1397 9.60262C12.8161 9.28783 12.3036 9.2524 11.9375 9.51934L10.4707 10.5895C10.2607 10.743 10.0051 10.8257 9.74218 10.8257H8.32576L9.22734 10.8257C9.73551 10.8257 10.1471 10.4253 10.1471 9.93094V9.752C10.1471 9.34154 9.85997 8.98364 9.45076 8.88442L8.05923 8.54601C7.83278 8.49108 7.60086 8.46333 7.36772 8.46333C6.80491 8.46333 5.78616 8.9293 5.78616 8.9293L4.07589 9.64451M12.2426 4.67166C12.2426 5.79924 11.3285 6.71333 10.2009 6.71333C9.07331 6.71333 8.15922 5.79924 8.15922 4.67166C8.15922 3.54408 9.07331 2.62999 10.2009 2.62999C11.3285 2.62999 12.2426 3.54408 12.2426 4.67166ZM1.74255 9.39666L1.74255 12.78C1.74255 13.1067 1.74255 13.27 1.80613 13.3948C1.86206 13.5046 1.9513 13.5938 2.06106 13.6497C2.18584 13.7133 2.34919 13.7133 2.67589 13.7133H3.14255C3.46925 13.7133 3.6326 13.7133 3.75738 13.6497C3.86714 13.5938 3.95638 13.5046 4.01231 13.3948C4.07589 13.27 4.07589 13.1067 4.07589 12.78V9.39666C4.07589 9.06996 4.07589 8.90661 4.01231 8.78183C3.95638 8.67207 3.86714 8.58283 3.75738 8.52691C3.6326 8.46333 3.46925 8.46333 3.14255 8.46333L2.67589 8.46333C2.34919 8.46333 2.18584 8.46333 2.06106 8.52691C1.9513 8.58283 1.86206 8.67207 1.80613 8.78183C1.74255 8.90661 1.74255 9.06996 1.74255 9.39666Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1626_6719'>
        <rect
          width='14'
          height='14'
          fill='currentColor'
          transform='translate(0.575928 0.879993)'
        />
      </clipPath>
    </defs>
  </svg>
)

export default CoinsHand
